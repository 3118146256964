import ArchiveIcon from "@mui/icons-material/Archive";
import MinorCrashIcon from "@mui/icons-material/MinorCrash";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { ErrorBoundary } from "@sentry/react";
import Cookies from "js-cookie";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { DARK_ACCENT, GRAY, MENU_ELEMENTS } from "../../../constants";
import { ThemeContext } from "../../../context";
import { ProfilePicture } from "../../../pages/UserProfile/common/ProfilePicture";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { DarkLightToggle } from "../../Forms/common";
import { closeAllChildElements } from "../subs/menuFunctions";

interface Props {
  activePage: string | undefined;
  firstName: string;
  lastName: string;
  email: string;
  cxoUrl: string;
  onClickCloseMenuHandler: () => void;
}

const MenuItems = withRouter((props: Props & RouteComponentProps): JSX.Element => {
  const { activePage, onClickCloseMenuHandler, firstName, lastName, email, cxoUrl, history } = props;

  const { theme } = useContext(ThemeContext);

  const croppedEmail = email?.split("@")[0];
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const screenWidth = window.matchMedia("(max-width: 768px)");
  useEffect(() => setIsMobile(screenWidth.matches), [screenWidth]);

  // Handles page navigation
  const onMenuItemClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      if (pageLink === "/dashboard_info" || pageLink === "/user_profile") {
        eventTracking(MixpanelEvents.menu_click, { link: pageLink });
      }

      if (history.location.pathname === pageLink) {
        window.location.reload();
      }
      history.push({ pathname: pageLink, search: "" });

      closeAllChildElements();
    },
    [history]
  );

  const myMenuItems = useMemo(() => {
    const viewsCookie = Cookies.get("views") as string;

    const views: string[] = viewsCookie ? JSON.parse(viewsCookie) : [];

    if (views.includes("annotation_admin")) {
      views.push("admin");
    }

    if (!views?.length) return [];

    const parentMenuIds = Object.keys(MENU_ELEMENTS).reduce((result: string[], parent) => {
      const { has_children, id } = MENU_ELEMENTS[parent];
      if (!has_children) {
        if (views.includes(id)) return [...result, id];

        return result;
      }

      return MENU_ELEMENTS[parent].children.some((child) => views.some((view) => child.id.includes(view)))
        ? [...result, MENU_ELEMENTS[parent].id]
        : result;
    }, []);

    return [...parentMenuIds, ...views];
  }, []);

  const onParentClickHandler = (evt: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
    const {
      currentTarget: { id: currentId, dataset },
    } = evt;
    const childClassToShow = dataset.childClass;
    const shownClass = "isShown";

    const targetElement = document.getElementById(currentId);
    const childElements = document.querySelectorAll(`.${childClassToShow}`);

    const parentIsShown = targetElement?.classList.contains(shownClass);

    if (!parentIsShown) {
      // Remove class from all child elements
      closeAllChildElements();

      targetElement?.classList.add(shownClass);
      childElements?.forEach((childElement) => childElement.classList.add(shownClass));
    } else {
      targetElement?.classList.remove(shownClass);
      childElements?.forEach((childElement) => childElement.classList.remove(shownClass));
    }
  };

  return (
    <ErrorBoundary>
      <ul id="profile" className="hide_on_mobile" data-test-id="menu">
        <button id="closeMenu" onClick={onClickCloseMenuHandler}>
          &#x2573;
        </button>
        {Object.keys(MENU_ELEMENTS).map((menu_parent: string) => {
          if (!myMenuItems.includes(MENU_ELEMENTS[menu_parent]?.id)) return;
          const { id, name, description, hide_on_mobile, has_children, hidden, path, children } = MENU_ELEMENTS[menu_parent];
          const hiddenClass = hidden ? "hidden" : "";
          const hideOnMobileClass = hide_on_mobile ? "hide_on_mobile" : "";

          if (!has_children && path) {
            const pageUrlRe = new RegExp(path as string, "gi");
            const isActiveClass = activePage && pageUrlRe.test(activePage) ? "active" : "";

            return (
              <li
                id={id}
                key={id}
                className={`profile_button ${hiddenClass} ${hideOnMobileClass} ${isActiveClass}`}
                data-page={path}
                data-test-id={id}
                onClick={onMenuItemClick}
              >
                <div className="icon" />
                <div className="text">
                  <p>{name}</p>
                </div>
              </li>
            );
          } else {
            const hasActiveChildClass =
              children.filter((childElem) => {
                const { path } = childElem;
                const pageUrlRe = new RegExp(path, "gi");

                return activePage && pageUrlRe.test(activePage);
              })?.length > 0
                ? "active"
                : "";

            const resultingElements =
              menu_parent === "profile"
                ? [
                  <li
                    key={id}
                    id={id}
                    className={`profile_button ${hiddenClass} ${hideOnMobileClass} ${hasActiveChildClass}`}
                    data-test-id={id}
                    data-child-class={`${menu_parent}_child`}
                    onMouseEnter={!isMobile ? onParentClickHandler : undefined}
                    onTouchStart={onParentClickHandler}
                  >
                    <div className="icon">
                      <ProfilePicture firstname={firstName || email || " "} lastname={lastName || " "} />
                    </div>
                    <div className="text">
                      <p>{firstName && lastName ? `${firstName} ${lastName}` : email ? croppedEmail : "user profile"}</p>
                    </div>
                    <span className="menu_arrow_down hide_on_desktop" />
                  </li>,
                ]
                : menu_parent === "theme_mode"
                  ? [
                    <li key={id} id={id} className={`profile_button  ${hiddenClass} ${hideOnMobileClass}`} data-test-id={id}>
                      <div className="icon" />
                      <div className="toggle" style={{ fontSize: "var(--regular_font_size)", margin: "auto 0" }}>
                        <DarkLightToggle />
                      </div>
                    </li>,
                  ]
                  : [
                    <li
                      key={id}
                      id={id}
                      className={`profile_button ${hiddenClass} ${hideOnMobileClass} ${hasActiveChildClass}`}
                      data-test-id={id}
                      data-child-class={`${menu_parent}_child`}
                      onClick={onParentClickHandler}
                    >
                      {id == "try_button" ? (
                        <SupportAgentIcon className="icon" sx={{ color: theme === "light" ? DARK_ACCENT : GRAY, fontSize: "5px" }} />
                      ) : id == "buy_button" ? (
                        <ShoppingCartIcon className="icon" sx={{ color: theme === "light" ? DARK_ACCENT : GRAY, fontSize: "5px" }} />
                      ) : id == "use_button" ? (
                        <MinorCrashIcon className="icon" sx={{ color: theme === "light" ? DARK_ACCENT : GRAY, fontSize: "5px" }} />
                      ) : id == "executive_reporting_button" ? (
                        <TrendingUpIcon className="icon" sx={{ color: theme === "light" ? DARK_ACCENT : GRAY, fontSize: "5px" }} />
                      ) : (
                        <ArchiveIcon className="icon" sx={{ color: theme === "light" ? DARK_ACCENT : GRAY, fontSize: "5px" }} />
                      )}

                      <div className="text">
                        <p>{name}</p>
                        <span className="menu_text_description">{description}</span>
                      </div>
                      <span className="menu_arrow_down" />
                    </li>,
                  ];

            children.map((child) => {
              const { id: child_id, name: child_name, path, hide_on_mobile: child_hide_on_mobile, hidden: child_hidden } = child;

              const childPageUrl = new RegExp(path, "gi");

              if (!myMenuItems.some((item) => child_id.includes(item))) return;

              const childHiddenClass = child_hidden ? "hidden" : "";
              const isActiveClass = activePage && childPageUrl.test(activePage) ? "active" : "";
              const childHideOnMobileClass = child_hide_on_mobile ? "hide_on_mobile" : "";
              const childClass = `${menu_parent}_child`;
              const myStyles = {
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
                flexFlow: "row",
                alignItems: "center",
                textDecoration: "none",
              };

              resultingElements.push(
                child_name === "CXO EXPERIMENT LIBRARY" ? (
                  <a
                    key={child_id}
                    id={child_id}
                    data-test-id={"cxo_experiment_button"}
                    className={`profile_button child_button ${childClass} ${childHiddenClass} ${childHideOnMobileClass} ${isActiveClass}`}
                    target="_blank"
                    href={cxoUrl}
                    //@ts-ignore
                    style={myStyles}
                    rel="noreferrer"
                    onClick={() => eventTracking(MixpanelEvents.cxo_access, { through: "Menu" })}
                  >
                    <div className="icon" data-test-id="menu__info--icon" />
                    <div className="text" data-test-id="menu__info--text">
                      <p>CXO EXPERIMENT LIBRARY</p>
                    </div>
                  </a>
                ) : (
                  <li
                    key={child_id}
                    id={child_id}
                    data-page={path}
                    className={`profile_button child_button ${childClass} ${childHiddenClass} ${childHideOnMobileClass} ${isActiveClass}`}
                    data-test-id={child_id}
                    onClick={onMenuItemClick}
                  >
                    <div className="icon" />
                    <div className="text">
                      <p>{child_name}</p>
                    </div>
                  </li>
                )
              );
            });

            return resultingElements.map((element) => element);
          }
        })}
      </ul>
    </ErrorBoundary>
  );
});

export default MenuItems;
