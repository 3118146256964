import React, { useEffect } from "react";
import Ratings from "../../../components/Ratings/Ratings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDmeoPerformanceAsPerObjective } from "../../../api/dmeoApi";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";

const PerformanceAsPerObjective = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["performanceAsPerObjectiveData", history.location.search],
    queryFn: getDmeoPerformanceAsPerObjective,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">Performance as per Objective</div>
      <div className="tile-content">
        <table className="performance-as-per-objective-table">
          {data?.map((dataItem: any) => (
            <tr key={dataItem.metric}>
              <td>{dataItem.metric}</td>
              <td>{`${dataItem.metric == "Digital Spend" ? "$" : ""}${dataItem.value?.toLocaleString()} `}</td>
              <td>
                <div className="comparison">
                  {dataItem.perc_pm && <Ratings value={dataItem.perc_pm} isPercentageValue={true} testValue={false} />}
                  {dataItem.perc_pm ? ` ${dataItem.perc_pm}% vs PM` : "n/a"}
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <LoadingEllipsis isLoading={isFetching} />
    </div>
  );
});

export default PerformanceAsPerObjective;
