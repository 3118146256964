import React, { useEffect } from "react";
import Ratings from "../../../components/Ratings/Ratings";
import { useQuery } from "@tanstack/react-query";
import { getDmeoDigitalSpend } from "../../../api/dmeoApi";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import { isNull } from "../../../utils/utilityFunctions";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";

const DigitalSpendTile = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["digitalSpendSummaryData", history.location.search],
    queryFn: getDmeoDigitalSpend,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile digital-spend-tile">
      <div className="tile-header">Digital Spend</div>
      <div className="tile-content">
        <div className="main-value">{data?.digital_spend ? `$${formatToThreeDigits(data.digital_spend)}` : "n/a"}</div>
        <div className="sub-text first-sub-text">
          {data?.mom_digital_spend && !isNull(data.mom_digital_spend) ? (
            <Ratings value={data?.mom_digital_spend} isPercentageValue={true} />
          ) : null}{" "}
          <div>{data?.mom_digital_spend && !isNull(data.mom_digital_spend) ? `${Math.abs(data.mom_digital_spend)}%` : "n/a"}</div>{" "}
          <div>&nbsp;vs PY</div>
        </div>
        <div className="sub-text">
          {data?.yoy_digital_spend && !isNull(data?.yoy_digital_spend) ? (
            <Ratings value={data?.yoy_digital_spend} isPercentageValue={true} />
          ) : null}{" "}
          <div>{data?.yoy_digital_spend && !isNull(data?.yoy_digital_spend) ? `${Math.abs(data.yoy_digital_spend)}%` : "n/a"}</div>{" "}
          <div>&nbsp;vs PM</div>
        </div>
      </div>
      <LoadingEllipsis isLoading={isFetching} />
    </div>
  );
});

export default DigitalSpendTile;
