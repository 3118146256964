import React, { useContext, useEffect } from "react";
import DmeoMultipleBarChart from "../../../components/Charts/Dmeo/DmeoMultipleBarChart";
import { ThemeContext } from "../../../context";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDmeoDigitalSpendByChannelTrend } from "../../../api/dmeoApi";

const DigitalSpendByChannelTrend = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["digitalSpendByChannelTrendData", history.location.search],
    queryFn: getDmeoDigitalSpendByChannelTrend,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">Digital Spend by Channel</div>
      <div className="tile-content">
        <DmeoMultipleBarChart
          chartId="digitalSpendByChannelBarChart"
          data={data?.data ? data.data : []}
          seriesList={data?.series ? data.series : []}
          isLoading={isFetching}
          theme={theme}
        />
      </div>
    </div>
  );
});

export default DigitalSpendByChannelTrend;
