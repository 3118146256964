import { Button } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { Breadcrumb } from "../../Breadcrumb/Breadcrumb";
import { closeFilterOptions } from "../subs/helpers";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

interface Props {
  onShowFilterOptions: any;
  availableRegions: {
    MC_Group: any;
    Subgroup: any;
    market: string[];
  }[];
}

type checkboxState = { value: string; checked: boolean };

type listTypes = "MC_Group" | "Subgroup" | "market";

export const MidasGeoFilter = (props: Props) => {
  const { onShowFilterOptions, availableRegions } = props;

  const dispatch = useDispatch();
  const brandParam = useSelector((state: RootStateOrAny) => state.parameters.brand);
  const mcGroupParam = useSelector((state: RootStateOrAny) => state.parameters.mc_group);
  const subGroupParam = useSelector((state: RootStateOrAny) => state.parameters.subgroup);
  const marketParam = useSelector((state: RootStateOrAny) => state.parameters.market);

  const [activeListState, setActiveList] = useState<listTypes>("MC_Group");
  const [selectionValue, setSelectionValue] = useState<string>("");

  const [mcGroups, setMcGroups] = useState<Array<checkboxState>>([]);
  const [subGroups, setSubGroups] = useState<Array<checkboxState>>([]);
  const [markets, setMarkets] = useState<Array<checkboxState>>([]);
  const [mcGroupsInitialized, setMcGroupsInitialized] = useState(false);
  const [subGroupsInitialized, setSubGroupsInitialized] = useState(false);
  const [marketsInitialized, setMarketsInitialized] = useState(false);

  const [allMarketsCheckBox, setAllMarketsCheckbox] = useState(true);

  useEffect(() => {
    setMcGroups(getCheckedSubList(availableRegions, "MC_Group"));
    setMcGroupsInitialized(false);
    setSubGroups(getCheckedSubList(availableRegions, "Subgroup"));
    setSubGroupsInitialized(false);
    setMarkets(getCheckedSubList(availableRegions, "market"));
    setMarketsInitialized(false);
  }, [availableRegions]);

  useEffect(() => {
    if (!mcGroupParam && mcGroups) {
      dispatch(setSpecificParameter("mc_group", ALL_OPTION_NO_SPACE));
    } else if (!mcGroupsInitialized && mcGroupParam && mcGroups.length > 0) {
      if (mcGroupParam == ALL_OPTION_NO_SPACE) {
        const updateMcGroups = mcGroups.some((mcGroup) => !mcGroup.checked);
        updateMcGroups && setMcGroups((prevState) => prevState.map((mcGroup) => ({ ...mcGroup, checked: true })));
        setMcGroupsInitialized(true);
      } else {
        const updateMcGroups = mcGroups.some((mcGroup) => mcGroupParam.includes(mcGroup.value) && mcGroup.checked == false);
        updateMcGroups &&
          setMcGroups((prevState) => prevState.map((mcGroup) => ({ ...mcGroup, checked: mcGroupParam.includes(mcGroup.value) })));
        setMcGroupsInitialized(true);
      }
    }
  }, [mcGroupsInitialized, mcGroupParam, mcGroups]);

  useEffect(() => {
    if (!subGroupParam) {
      dispatch(setSpecificParameter("subgroup", ALL_OPTION_NO_SPACE));
    }

    if (mcGroupsInitialized && !subGroupsInitialized && subGroupParam && subGroupParam == ALL_OPTION_NO_SPACE && subGroups?.length > 0) {
      const mcGroupsValues = mcGroups.filter((mcGroup) => mcGroup.checked).map((mcGroup) => mcGroup.value);
      const updateSubGroups = subGroups.some((subGroup) => !subGroup.checked);
      const geos = availableRegions.filter((geo) => mcGroupsValues.includes(geo.MC_Group));
      updateSubGroups && setSubGroups(getCheckedSubList(geos, "Subgroup", true));
      setSubGroupsInitialized(true);
    } else if (mcGroupsInitialized && !subGroupsInitialized && subGroupParam && mcGroups?.length > 0 && subGroups?.length > 0) {
      const mcGroupsValues = mcGroups.filter((mcGroup) => mcGroup.checked).map((mcGroup) => mcGroup.value);
      const updateSubgroups = subGroups.some((subGroup) => !subGroupParam.includes(subGroup.value) || subGroup.checked == false);
      const geos = availableRegions.filter((geo) => mcGroupsValues.includes(geo.MC_Group));
      updateSubgroups &&
        setSubGroups(
          getCheckedSubList(geos, "Subgroup").map((subGroup) => ({ ...subGroup, checked: subGroupParam.includes(subGroup.value) }))
        );
      setSubGroupsInitialized(true);
    }
  }, [availableRegions, mcGroupsInitialized, subGroupsInitialized, subGroupParam, mcGroups, subGroups]);

  useEffect(() => {
    if (!marketParam) {
      dispatch(setSpecificParameter("market", ALL_OPTION_NO_SPACE));
    }

    if (
      mcGroupsInitialized &&
      subGroupsInitialized &&
      !marketsInitialized &&
      marketParam &&
      marketParam == ALL_OPTION_NO_SPACE &&
      subGroups?.length > 0 &&
      markets?.length > 0
    ) {
      const subGroupsValues = subGroups.filter((subGroup) => subGroup.checked).map((subGroup) => subGroup.value);
      const updatemarkets = markets.some((market) => !market.checked);
      const geos = availableRegions.filter((geo) => subGroupsValues.includes(geo.Subgroup));
      updatemarkets && setMarkets(getCheckedSubList(geos, "market", true));
      setMarketsInitialized(true);
      setAllMarketsCheckbox(true);
    } else if (
      mcGroupsInitialized &&
      subGroupsInitialized &&
      !marketsInitialized &&
      marketParam &&
      subGroups?.length > 0 &&
      markets?.length > 0
    ) {
      const subGroupsValues = subGroups.filter((subGroup) => subGroup.checked).map((subGroup) => subGroup.value);
      const updateMarkets = markets.some((market) => !marketParam.includes(market.value) || market.checked == false);
      const geos = availableRegions.filter((geo) => subGroupsValues.includes(geo.Subgroup));
      updateMarkets &&
        setMarkets(getCheckedSubList(geos, "market").map((market) => ({ ...market, checked: marketParam.includes(market.value) })));
      setMarketsInitialized(true);
      setAllMarketsCheckbox(false);
    }
  }, [availableRegions, mcGroupsInitialized, subGroupsInitialized, marketsInitialized, marketParam, subGroups, markets]);

  // Updates selection value
  useEffect(() => {
    if (mcGroups?.length && subGroups?.length && markets?.length) {
      const checkedSubGroups = subGroups?.filter((subGroup) => subGroup.checked);
      const checkedMarkets = markets?.filter((market) => market.checked);

      if (checkedMarkets?.length && checkedMarkets?.length == markets?.length) {
        setSelectionValue(`${checkedSubGroups.length} sub groups selected`);
        return;
      } else if (checkedMarkets?.length != 0) {
        setSelectionValue(`${checkedMarkets.length} markets selected`);
        return;
      }

      setSelectionValue("");
    }
  }, [mcGroups, subGroups, markets]);

  const getCheckedSubList = (
    geosForMarket: {
      MC_Group: any;
      Subgroup: any;
      market: string[];
    }[],
    key: listTypes,
    allChecked?: boolean
  ) => {
    const list: string[] = [];
    geosForMarket?.map((val) => {
      if (val[`${key}`]) {
        if (key == "market") {
          list.push(...val[`${key}`]);
        } else {
          list.push(val[`${key}`]);
        }
      }
    });
    const uniqueItems = Array.from(new Set(list));

    return [
      ...uniqueItems.map((val) => ({
        value: val,
        checked: Boolean(allChecked),
      })),
    ];
  };

  const resetSelection = useCallback(
    (listType?: listTypes) => {
      const updatedMcGroup = mcGroups.map((mcGroup) => ({ ...mcGroup, checked: false }));
      const updatedSubGroups = subGroups.map((subGroup) => ({ ...subGroup, checked: false }));
      const updatedMarkets = markets.map((market) => ({ ...market, checked: false }));
      if (!listType) {
        setMcGroups(updatedMcGroup);
        setSubGroups(updatedSubGroups);
        setMarkets(updatedMarkets);
      } else {
        if (listType === "MC_Group") setMcGroups(updatedMcGroup);
        if (listType === "Subgroup") setSubGroups(updatedSubGroups);
        if (listType === "market") setMarkets(updatedMarkets);
      }
    },
    [mcGroups, markets, subGroups]
  );

  const breadcrumbSelection = useCallback(
    () => (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const breadcrumbValue = dataset?.value;
      setActiveList(breadcrumbValue as listTypes);
    },
    [setActiveList]
  );

  const handleOnlyBtnClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const listType = dataset.listType as listTypes;

      if (listType == "MC_Group") {
        const geos = availableRegions?.filter((val) => val?.MC_Group == dataset.value);
        setMcGroups((prevState) => prevState.map((mcGroup) => ({ ...mcGroup, checked: mcGroup.value == dataset.value })));
        setSubGroups(getCheckedSubList(geos, "Subgroup", true));
        setMarkets(getCheckedSubList(geos, "market", true));
        setAllMarketsCheckbox(true);
      }

      if (listType == "Subgroup") {
        const geos = availableRegions?.filter((val) => val.Subgroup == dataset.value);
        const subGroupMcGroups = availableRegions.filter((region) => region.Subgroup == dataset.value).map((region) => region.MC_Group);
        setSubGroups((prevState) => prevState.map((subGroup) => ({ ...subGroup, checked: subGroup.value == dataset.value })));
        setMarkets(getCheckedSubList(geos, "market", true));
        setMcGroups((prevState) => prevState.map((mcGroup) => ({ ...mcGroup, checked: subGroupMcGroups.includes(mcGroup.value) })));
        setAllMarketsCheckbox(true);
      }

      if (listType == "market") {
        const checkedMarkets = markets.filter((market) => market.value == dataset.value).map((market) => market.value);
        const marketsSubGroups = availableRegions
          .filter((region) => region.market.some((market) => checkedMarkets.includes(market)))
          .map((region) => region.Subgroup);
        const marketsMcGroups = availableRegions
          .filter((region) => region.market.some((market) => checkedMarkets.includes(market)))
          .map((region) => region.MC_Group);
        setMarkets((prevState) => prevState.map((market) => ({ ...market, checked: market.value == dataset.value })));
        setSubGroups((prevState) => prevState.map((subGroup) => ({ ...subGroup, checked: marketsSubGroups.includes(subGroup.value) })));
        setMcGroups((prevState) => prevState.map((mcGroup) => ({ ...mcGroup, checked: marketsMcGroups.includes(mcGroup.value) })));
        setAllMarketsCheckbox(false);
      }
    },
    [markets, mcGroups, subGroups]
  );

  const showAllOptions = useCallback(
    (listType?: listTypes) => {
      if (listType === "MC_Group") setMcGroups((prevMcGroup) => prevMcGroup.map((mcGroup) => ({ ...mcGroup, checked: true })));
      if (listType === "Subgroup") setSubGroups((prevSubGroup) => prevSubGroup.map((SubGroup) => ({ ...SubGroup, checked: true })));
      if (listType === "market") setMarkets((prevMarket) => prevMarket.map((market) => ({ ...market, checked: true })));
    },
    [mcGroups, subGroups, markets]
  );

  const handleCheckboxClick = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value: checkboxValue, checked: isChecked, dataset },
      } = evt;

      // If All is deselected, clear all selections
      if (checkboxValue === ALL_OPTION_NO_SPACE && (dataset?.listType as listTypes) !== "MC_Group") {
        if (!isChecked) {
          resetSelection(dataset?.listType as listTypes);
        } else {
          showAllOptions(dataset?.listType as listTypes);
        }
        return;
      }

      const listType = dataset?.listType as listTypes;

      const listCopy = listType == "MC_Group" ? [...mcGroups] : listType == "Subgroup" ? [...subGroups] : [...markets];
      const index = Number(dataset.key);
      listCopy[index] = { value: checkboxValue, checked: isChecked };

      const listWithoutAll = listCopy?.filter((val) => val?.value !== ALL_OPTION_NO_SPACE);

      if (listType == "MC_Group") {
        const checkedMcGroups = listWithoutAll?.filter((mcGroup) => mcGroup.checked).map((item) => item.value);
        const geos = availableRegions?.filter((val) => checkedMcGroups.includes(val?.MC_Group));
        setMcGroups(listWithoutAll);
        setSubGroups(getCheckedSubList(geos, "Subgroup", true));
        setMarkets(getCheckedSubList(geos, "market", true));
      }

      if (listType == "Subgroup") {
        const checkedSubGroups = listWithoutAll?.filter((subGroup) => subGroup.checked).map((item) => item.value);
        const subGroupMcGroups = availableRegions
          ?.filter((region) => checkedSubGroups.includes(region.Subgroup))
          .map((region) => region.MC_Group);
        const geos = availableRegions?.filter((val) => checkedSubGroups.includes(val?.Subgroup));
        setSubGroups(listWithoutAll);
        setMarkets(getCheckedSubList(geos, "market", true));
        setMcGroups((prevState) => prevState.map((mcGroup) => ({ ...mcGroup, checked: subGroupMcGroups.includes(mcGroup.value) })));
      }

      if (listType == "market") {
        const checkedMarkets = listWithoutAll.filter((market) => market.checked).map((market) => market.value);
        const marketsSubRegions = availableRegions
          .filter((region) => region.market.some((market) => checkedMarkets.includes(market)))
          .map((region) => region.Subgroup);
        const marketsMcGroups = availableRegions
          .filter((region) => region.market.some((market) => checkedMarkets.includes(market)))
          .map((region) => region.MC_Group);
        setMarkets(listWithoutAll);
        if (marketsSubRegions.length > 0)
          setSubGroups((prevState) => prevState.map((subGroup) => ({ ...subGroup, checked: marketsSubRegions.includes(subGroup.value) })));
        if (marketsMcGroups.length > 0)
          setMcGroups((prevState) => prevState.map((mcGroup) => ({ ...mcGroup, checked: marketsMcGroups.includes(mcGroup.value) })));
        setAllMarketsCheckbox(false);
      }
    },
    [markets, mcGroups, subGroups]
  );

  const renderList = (list: Array<checkboxState>, listType: listTypes) => {
    return list?.map(({ value, checked }, index) => (
      <div className={"multiselect_container geography_list"} key={value}>
        <input
          type="checkbox"
          className={`multiselect-checkbox ${listType}-checkbox geography_checkbox`}
          id={`${listType}_${value?.replace(" ", "_")}`}
          name={`${listType}_${value.replace(" ", "_")}`}
          value={value}
          data-list-type={listType}
          data-key={index}
          checked={checked}
          onChange={handleCheckboxClick}
        />
        <label className="multiselect-label geography_label" htmlFor={`${listType}_${value.replace(" ", "_")}`}>
          {value}
        </label>

        {!/all/gi.test(value) && (
          <Button
            onClick={handleOnlyBtnClick}
            className={"geographyOptionBtn"}
            size={"small"}
            variant={"outlined"}
            data-list-type={listType}
            data-value={value}
          >
            ONLY
          </Button>
        )}
      </div>
    ));
  };

  const selectAllMcGroups = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { checked: isChecked },
      } = evt;
      if (isChecked) {
        setMcGroups(getCheckedSubList(availableRegions, "MC_Group", true));
        setSubGroups(getCheckedSubList(availableRegions, "Subgroup", true));
        setMarkets(getCheckedSubList(availableRegions, "market", true));
        setAllMarketsCheckbox(true);
      } else {
        setMcGroups(getCheckedSubList(availableRegions, "MC_Group"));
        setSubGroups([]);
        setMarkets([]);
      }
    },
    [availableRegions]
  );

  const selectAllSubGroups = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { checked: isChecked },
      } = evt;
      const checkedMcGroup = mcGroups?.filter((val) => val.checked)?.map((val) => val.value);
      const geos = availableRegions?.filter((val) => checkedMcGroup.includes(val?.MC_Group));
      if (isChecked) {
        setSubGroups(getCheckedSubList(geos, "Subgroup", true));
        setMarkets(getCheckedSubList(geos, "market", true));
        setAllMarketsCheckbox(true);
      } else {
        setSubGroups(getCheckedSubList(geos, "Subgroup"));
        setMarkets([]);
      }
    },
    [mcGroups, availableRegions]
  );

  const selectAllMarkets = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { checked: isChecked },
      } = evt;
      const checkedMcGroups = mcGroups?.filter((val) => val.checked)?.map((val) => val.value);
      const checkedSubGroups = subGroups?.filter((val) => val.checked)?.map((val) => val.value);
      const geos = availableRegions?.filter((val) => checkedMcGroups.includes(val?.MC_Group) && checkedSubGroups?.includes(val?.Subgroup));
      if (isChecked) {
        setMarkets(getCheckedSubList(geos, "market", true));
      } else {
        setMarkets(getCheckedSubList(geos, "market"));
      }
      setAllMarketsCheckbox((prevState) => !prevState);
    },
    [mcGroups, subGroups, availableRegions, allMarketsCheckBox]
  );

  const handleSubmission = useCallback(
    (evt) => {
      evt.preventDefault();

      if (selectionValue === "") return;
      const checkedMcGroup = mcGroups?.filter((mcGroup) => mcGroup.checked);
      const selectedMcGroup = checkedMcGroup?.map((val) => val.value)?.toString();

      const checkedSubGroup = subGroups?.filter((val) => val.checked);
      const selectedSubGroup = checkedSubGroup?.map((val) => val.value)?.toString();

      const checkedMarkets = markets?.filter((val) => val.checked);
      const selectedMarket = checkedMarkets?.map((val) => val.value)?.toString();

      dispatch(setSpecificParameter("mc_group", selectedMcGroup));
      dispatch(setSpecificParameter("subgroup", selectedSubGroup));
      dispatch(setSpecificParameter("market", selectedMarket));

      eventTracking(MixpanelEvents.filter_change, { filter: "mc_group", selectedMcGroup, dashboard: "Midas" });
      eventTracking(MixpanelEvents.filter_change, { filter: "subgroup", selectedSubGroup, dashboard: "Midas" });
      eventTracking(MixpanelEvents.filter_change, { filter: "market", selectedMarket, dashboard: "Midas" });

      closeFilterOptions();
    },
    [markets, mcGroups, subGroups, selectionValue, allMarketsCheckBox]
  );

  return (
    <div className="filter" id="geography_filter_div">
      <label className="filter_header" htmlFor={"region_country_filter"}>
        MARKET
      </label>

      <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={"geography_selector"}>
        <input readOnly type="text" id="region_country_filter" className={"filter_input"} data-test-id="region" value={selectionValue} />
        <span className="arrow_down " />
      </div>

      <div className="filter_options" id="geography_selector" data-test-id="geography_selector">
        <div className="search_div">
          <div className="selection_label hide_on_mobile">
            <p className={"selection_value"}>{selectionValue}</p>
          </div>

          <div className="search_clear_div">
            <div className="clear_btn" onClick={() => resetSelection()}>
              {" "}
              Clear all{" "}
            </div>
          </div>
        </div>
        <div className="breadcrumb_container hide_on_desktop">
          <Breadcrumb
            list={["Market", "Region", "Territory", "Area", "District"]}
            onBreadcrumbClick={breadcrumbSelection()}
            activeValue={activeListState}
          />
        </div>
        <div className="geography_options_title hide_on_mobile">
          <div className="geography_type">
            <p className="title">MC Groups</p>
          </div>
          <div className={`geography_type ${subGroups.length ? "" : "isHidden"}`}>
            <p className="title">Sub Groups</p>
          </div>
          <div className="geography_type">
            <p className="title">Markets</p>
          </div>
        </div>
        <div className="geography_options">
          {/* Mc Groups */}
          <div className={`marketList ${activeListState === "MC_Group" ? "active" : ""}`} id="mc_group_list" data-test-id={"mc_group_list"}>
            <div className={"multiselect_container geography_list"} key={"allMcGroups"}>
              <input
                type="checkbox"
                className={`multiselect-checkbox geography_checkbox`}
                id={`mc_group_all`}
                name={`mc_group_all`}
                value={ALL_OPTION_NO_SPACE}
                checked={mcGroups.filter((item) => item.checked).length === mcGroups.length}
                ref={(input) => {
                  if (input) {
                    const checkedMcGroups = mcGroups.filter((item) => item.checked);
                    input.indeterminate = checkedMcGroups.length > 0 && checkedMcGroups.length < mcGroups.length;
                  }
                }}
                onChange={selectAllMcGroups}
              />
              <label className="multiselect-label geography_label" htmlFor={`mc_group_all`}>
                {ALL_OPTION_NO_SPACE}
              </label>
            </div>
            {renderList(mcGroups, "MC_Group")}
          </div>
          {/* Sub groups */}
          <div
            className={`marketList ${activeListState === "Subgroup" ? "active" : ""} ${subGroups.length ? "" : "isHidden"}`}
            id="sub_group_list"
            data-test-id={"sub_group_list"}
          >
            <div className={"multiselect_container geography_list"} key={"allSubGroup"}>
              <input
                type="checkbox"
                className={`multiselect-checkbox geography_checkbox`}
                id={`sub_group_all`}
                name={`sub_group_all`}
                value={ALL_OPTION_NO_SPACE}
                checked={subGroups.filter((item) => item.checked).length === subGroups.length}
                ref={(input) => {
                  if (input) {
                    const checkedSubGroups = subGroups.filter((item) => item.checked);
                    input.indeterminate = checkedSubGroups.length > 0 && checkedSubGroups.length < subGroups.length;
                  }
                }}
                onChange={selectAllSubGroups}
              />
              <label className="multiselect-label geography_label" htmlFor={`sub_group_all`}>
                {ALL_OPTION_NO_SPACE}
              </label>
            </div>
            {renderList(subGroups, "Subgroup")}
          </div>

          {/* Markets */}
          <div className={`marketList ${activeListState === "market" ? "active" : ""}`} id="market_list" data-test-id={"market_list"}>
            {markets.length ? (
              <>
                <div className={"multiselect_container geography_list"} key={"allMarket"}>
                  <input
                    type="checkbox"
                    className={`multiselect-checkbox geography_checkbox`}
                    id={`market_all`}
                    name={`market_all`}
                    value={ALL_OPTION_NO_SPACE}
                    checked={allMarketsCheckBox}
                    onChange={selectAllMarkets}
                  />
                  <label className="multiselect-label geography_label" htmlFor={`market_all`}>
                    {ALL_OPTION_NO_SPACE}
                  </label>
                </div>
                {renderList(markets, "market")}
              </>
            ) : (
              <ul>
                <li className={"error_msg"} style={{ fontSize: "0.9rem" }}>
                  Please select a sub group to see a list of markets
                </li>
              </ul>
            )}
          </div>
        </div>
        {/* Apply btn */}
        <div className="geography_options__submit_area">
          <button className="filter_button no_icon" onClick={handleSubmission} data-test-id={"geography_apply_btn"}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};
