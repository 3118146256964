import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../AppMessages";
import { DefaultTable } from "./subs";
import { labelNumberConversion } from "../../utils/utilityFunctions";

const MtpCategoryTable = (props: {
  tableData: any;
  tableId: string;
  keys: string[];
  months?: any[];
  selectedref?: React.MutableRefObject<any>;
}) => {
  const { keys, tableData, tableId, months, selectedref } = props;
  const period = useSelector((state: RootStateOrAny) => state.parameters.period);
  const allYearScenarios = useSelector((state: RootStateOrAny) => state.filters.midas_filters.yr_scnr_rv);
  const { unit: unitParamValue } = useSelector((state: RootStateOrAny) => state.parameters);
  const sortedKeys = keys?.sort(
    (a, b) => allYearScenarios?.indexOf(a?.replace(/FY/g, "FY ")) - allYearScenarios?.indexOf(b?.replace(/FY/g, "FY "))
  );
  const monthsOrder = period == "FY Month" ? [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 0] : [1, 2, 3, 4, 0];

  const mainMtpCategories = ["Media", "Fixed", "Strategic", "Total"];

  const extraClass = useMemo(() => {
    let text = "midas mtp";
    if (tableId?.includes("q1")) {
      text += " q1";
    } else if (tableId?.includes("fullyear")) {
      text += " fullYear";
    } else if (tableId?.includes("combined")) {
      text += " combined";
    } else if (tableId?.includes("mtpModels")) {
      text += " modelsTable";
    } else {
      text += " mtp_not_q1";
    }

    if (tableId?.includes("no_var")) text += " no_var";
    if (period == "FY Month") text += " month";
    if (period == "FY Quarter") text += " quarter";
    return text;
  }, [tableId, period]);

  const modelCol = [
    {
      id: "mtp_category",
      Header: "",
      accessor: `mtp_category`,
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericIgnoreCase",
      Cell: (props: {
        row: { values: { break_down_data?: any }; getToggleRowExpandedProps: () => any };
        cell: { row: { original: any } };
      }) => {
        const {
          cell: {
            row: { original },
          },
        } = props;

        const value = original?.["fmi_category"];
        const disable_click = "disable_click";

        const spanProps = {};

        const parentValue = original?.parent ? original?.parent : "";
        return (
          <span className={`${disable_click} `} data-type={"market"} {...spanProps} data-value={value} data-parent={parentValue}>
            {value}
          </span>
        );
      },
    },
  ];

  const cols: any[] | undefined = useMemo(() => {
    return months
      ?.sort((a, b) => monthsOrder.indexOf(a) - monthsOrder.indexOf(b))
      ?.map((month: number) => {
        if (sortedKeys?.length < 2) {
          return [
            {
              id: `amount${month}${sortedKeys[0]}fy0`,
              Header: `${sortedKeys[0]}`,
              accessor: `amount_${month}_${sortedKeys[0]}`,
              defaultCanSort: true,
              defaultSortDesc: true,
              sortDescFirst: true,
              sortType: "alphanumericCustom",
              Cell: ({ value }: { value: string }) => labelNumberConversion(value, unitParamValue),
            },
          ];
        } else {
          return [
            {
              id: `amount${month}${sortedKeys[0]}fy0`,
              Header: `${sortedKeys[0]}`,
              accessor: `amount_${month}_${sortedKeys[0]}`,
              defaultCanSort: true,
              defaultSortDesc: true,
              sortDescFirst: true,
              sortType: "alphanumericCustom",
              Cell: ({ value }: { value: string }) => labelNumberConversion(value, unitParamValue),
            },
            {
              id: `amount${month}${sortedKeys[1]}fy1`,
              Header: sortedKeys[1],
              accessor: `amount_${month}_${sortedKeys[1]}`,
              defaultCanSort: true,
              defaultSortDesc: true,
              sortDescFirst: true,
              sortType: "alphanumericCustom",
              Cell: ({ value }: { value: string }) => labelNumberConversion(value, unitParamValue),
            },
            {
              id: `mtpvariance${month}${sortedKeys[0]}`,
              Header: "Var",
              accessor: `variance_${month}_${sortedKeys[0]}`,
              defaultCanSort: true,
              defaultSortDesc: true,
              sortDescFirst: true,
              sortType: "alphanumericCustom",
              Cell: ({ value }: { value: string }) => labelNumberConversion(value, unitParamValue),
            },
          ];
        }
      })
      .flat();
  }, [months, sortedKeys]);

  const renderRowSubComponent = useCallback(
    // eslint-disable-next-line react/display-name
    () => (props: { row: { original: { break_down_data?: any } } }) => {
      const {
        row: { original: data },
      } = props;

      const breakDownData: any = data.break_down_data;

      const formattedBreakdownData = breakDownData;

      return (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <>
            {formattedBreakdownData?.length > 0 && (
              <DefaultTable
                columns={tableId == "mtpModels" ? [...modelCol] : [...modelCol, ...cols!]}
                data={formattedBreakdownData}
                id={"mtp_category_breakdown_child"}
                //@ts-ignore
                isChildComponent={true}
                initialSortColId={{ id: "visits", desc: true }}
                hiddenCols={[]}
                extraClass={extraClass}
                hideHeader
              />
            )}
          </>
        </ErrorBoundary>
      );
    },
    [cols]
  );
  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        {tableData?.length && (
          <>
            <DefaultTable
              columns={tableId == "mtpModels" ? [...modelCol] : [...modelCol, ...cols!]}
              data={tableData}
              id={tableId == "mtpModels" ? "mtpModels" : "mtp_category_breakdown"}
              // @ts-ignore
              renderRowSubComponent={renderRowSubComponent()}
              data-test-id={"mtp_category_breakdown"}
              isChildComponent={false}
              initialSortColId={{ id: "visits", desc: true }}
              hideHeader
              hiddenCols={[]}
              extraClass={extraClass}
              parentColumns={mainMtpCategories}
              selectedref={selectedref}
            />
          </>
        )}
      </>
    </ErrorBoundary>
  );
};

export default MtpCategoryTable;
