import { OCE_DATE_FILTER_OPTIONS } from "../constants";
import moment from "moment";
import { fetchDataV2, newApiFetchV2 } from "./utils";

export const getParams = () => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters.get("date_range");
  const marketParam = parameters.get("market") == "All" ? [] : parameters.get("market")?.split(",");
  const channelParam = parameters.get("channel") == "All" ? [] : parameters.get("channel")?.split(",");
  const objectiveParam = parameters.get("campaign_objective") == "All" ? [] : parameters.get("campaign_objective")?.split(",");
  const modelParam = parameters.get("model") == "All" ? [] : parameters.get("model")?.split(",");
  const campaignNameParam = parameters.get("campaign_name") == "All" ? [] : parameters.get("campaign_name")?.split(",");
  const landingPageParam = parameters.get("landing_page") ? parameters.get("landing_page") == "All" ? [] : parameters.get("landing_page")?.split(",") : null;

  const paramObj: any = {
    market: marketParam,
    channel: channelParam,
    objective: objectiveParam,
    model: modelParam,
    campaign_name: campaignNameParam,
    ...(landingPageParam && { landing_page: landingPageParam })
  };

  if (dateRangeParam) {
    if (OCE_DATE_FILTER_OPTIONS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "MMMM YYYY").format("YYYY-MM-DD");
      paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    }
  }

  return paramObj;
};

export const getDmeoLastDataRefresh = () => fetchDataV2("/dmeo/get-last-refresh-date");
export const getDmeoDigitalSpend = () => newApiFetchV2("/dmeo/digital-spend", getParams());
export const getDmeoDigitalSpendByCampaignObjective = () => newApiFetchV2("/dmeo/digital-spend-by-campaign-objective", getParams());
export const getDmeoDigitalSpendByChannel = () => newApiFetchV2("/dmeo/digital-spend-by-channel", getParams());
export const getDmeoVisists = () => newApiFetchV2("/dmeo/get-visits", getParams());
export const getDmeoVisistsBreakdown = () => newApiFetchV2("/dmeo/get-visits-breakdown", getParams());
export const getCostPerAquisition = (isLeads: boolean) =>
  newApiFetchV2(`/dmeo/get-cost-per-acquisition?metric=${isLeads ? "leads" : "kbas"}`, getParams());
export const getConversionRate = (isLeads: boolean) =>
  newApiFetchV2(`/dmeo/get-conversion-rate?metric=${isLeads ? "leads" : "kbas"}`, getParams());
export const getLandingPagePerformance = () => newApiFetchV2("/dmeo/get-landing-page-performance", getParams());
export const getDmeoDigitalSpendTrend = () => newApiFetchV2("/dmeo/get-digital-spend-trend-data", getParams());
export const getDmeoDigitalSpendByCampaignObjectiveTrend = () =>
  newApiFetchV2("/dmeo/digital-spend-by-campaign-objective-trend-data", getParams());
export const getDmeoDigitalSpendByChannelTrend = () =>
  newApiFetchV2("/dmeo/digital-spend-by-channel-trend-data?use_cache=false", getParams());
export const getDmeoDigitalSpendTopTenCampaigns = () => newApiFetchV2("/dmeo/get-digital-spend-top-ten-campaigns", getParams());
export const getDmeoKpiPerformance = (metric: string) =>
  newApiFetchV2(`/dmeo/get-campaign-kpi-performance-data?metric=${metric}`, getParams());
export const getDmeoDigitalPerformanceEvolution = () => newApiFetchV2("/dmeo/get-campaign-evolution-data", getParams());
export const getDmeoPerformanceAsPerObjective = () => newApiFetchV2("/dmeo/get-campaign-performance-as-per-objective", getParams());
export const getDmeoCampaignPerformanceDetails = () => newApiFetchV2("/dmeo/get-campaign-performance-details", getParams());
export const getDmeoLandingPagePerformance = () => newApiFetchV2("/dmeo/get-landing-page-performance-totals", getParams());
export const getDmeoConversionRate = (isLeads: boolean) =>
  newApiFetchV2(`/dmeo/get-landing-page-conversion-rate?metric=${isLeads ? "leads" : "kbas"}`, getParams());
export const getDmeoBounceRate = (isLeads: boolean) =>
  newApiFetchV2(`/dmeo/get-bounce-rate?metric=${isLeads ? "leads" : "kbas"}`, getParams());
export const getLandingPageEvaluationMetrics = () => newApiFetchV2("/dmeo/get-landing-page-performance-table-data", getParams());
export const getDMEODataDefinition = () => newApiFetchV2("/dmeo/get-data-definition", getParams());
